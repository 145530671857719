import React from 'react';

const Reading = props => (
  <div className="d-flex justify-content-between align-items-center">
    <button 
      className="btn btn-link text-decoration-none font-weight-bold"
      onClick={props.previous}
      disabled={!props.hasPrevious}
    >
      &lt;
    </button>
    <div>
      {props.reading.amount}mm | {props.reading.date.format('Do MMMM YYYY')}
    </div>
    <button 
      className="btn btn-link text-decoration-none font-weight-bold"
      onClick={props.next}
      disabled={!props.hasNext}
    >
      &gt;
    </button>
  </div>
);

export default Reading;